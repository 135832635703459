import React from "react";
import styled from "styled-components";
import RelatedContentCardFlex from "./RelatedContentCardFlex";

const StyledRelatedContent = styled.section`
  margin: 2rem auto;

  .related-content-header {
    & h4 {
      font-family: -apple-system, Arial, "Helvetica", "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", sans-serif;
      font-size: 1.2rem;
      color: var(--foreground);
      margin-bottom: 0.5rem;
      &:after {
        content: "...";
      }
    }
  }

  .related-content {
    display: flex;
    flex-wrap: wrap;
    background: var(--background);
  }
`;

const RelatedContent = props => {
  return (
    <StyledRelatedContent className="related-content-container">
      <div className="related-content-header">
        <h4>Related Content</h4>
      </div>
      <div className="related-content">
        <RelatedContentCardFlex
          imageName="funnel"
          // imageName="google-analytics-setup/funnel.png"
          title="Google Analytics Setup for the keen ones (Updated)"
          link="/hamburg"
        />
        <RelatedContentCardFlex
          imageName="conversions"
          // imageName="google-analytics-setup/conversions.jpg"
          title="10 best heatmap tools on MacOS"
          link="/hamburg"
        />
        <RelatedContentCardFlex
          imageName="heatmap"
          // imageName="google-analytics-setup/heatmap.jpg"
          title="How to get all traffic on the Internet"
          link="/hamburg"
        />
        <RelatedContentCardFlex
          imageName="scroll-tracking"
          // imageName="google-analytics-setup/heatmap.jpg"
          title="I did it. The Internet is mine."
          link="/hamburg"
        />
      </div>
    </StyledRelatedContent>
  );
};

export default React.memo(RelatedContent);
