import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";

import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";

import RelatedContent from "../../components/RelatedContent";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  "Analytics Consulting": "/en/analytics-consulting"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/analytics-consulting"
  },
  {
    hreflang: "de",
    href: "/de/google-analytics-beratung"
  },
  {
    hreflang: "x-default",
    href: "/en/analytics-consulting"
  }
];

const AnalyticsConsulting = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Analytics Consulting as a service for Google Analytics & Co."
        description="Certified analytics consulting services online and remote for web analytics with Google Tag Manager, Google Analytics & Co."
        lang="en"
        image="google-analytics-setup-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <Img
          src="google-analytics-setup/google-analytics-setup-hero.jpg"
          alt="man working on google analytics setup with laptop"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Analytics Consulting</H>
        <p>
          Companies and business owners want to know how to optimize their digital strategy.
          <br />
          If they were able to base decisions on data, they could reduce the risks of investing in the wrong channels.
        </p>
        <p>But to get there, a few things have to be decided first.</p>
        <ul>
          <li>What should be tracked? What do we need to know?</li>
          <li>How are we gonna collect the data?</li>
          <li>How will we test insights and catch wrong assumptions?</li>
          <li>How shall data be visualized for the relevant teams?</li>
          <li>What is gonna happen after the first round of changes? Are we gonna review our assumptions?</li>
          <li>How can we introduce a continuous cycle of measuring, data analysis, testing and implementation?</li>
        </ul>
        <p>
          <strong>Analytics consulting</strong> guides you through this journey. From data <strong>collection</strong>
          , <strong>analysis</strong>, to making <strong>data-driven decisions</strong>. Finally, you'll have a
          continuous <strong>optimization process</strong> for your online business.
        </p>
        <H as="h2">What is analytics consulting?</H>
        <p>
          Analytics consulting is a service that covers guidance across all web analytics disciplines. From{" "}
          <Link to="/en/google-analytics-setup">setting up google analytics</Link> with the right KPIs, to integrating
          third-party data. Making the data accessible to teams through data visualization is another step. The goal
          is to introduce a continuous process of data collection, analysis, implementation and review.
        </p>
        <H as="h4">Key areas of analytics consulting services</H>
        <ul>
          <li>Planning of data collection, analysis and optimization cycles</li>
          <li>Selection and implementation of analytics tools</li>
          <li>Definition of KPIs and metrics</li>
          <li>Measuring user behavior</li>
          <li>User segmentation</li>
          <li>Data Integration with third-party services</li>
          <li>
            {/* <Link to="/en/data-visualization"> */}
            Data visualization
            {/* </Link> */}
          </li>
        </ul>
        <H as="h2">Web analytics help for any type of website</H>
        <p>There is not one analytics solution that fits all websites.</p>
        <p>
          If your website focuses solely on content, for example, your tracking system should too. A few user behavior
          and content metrics are already enough to see what visitors are after.
        </p>
        <p>
          On e-commerce websites, user behavior is important too, but usually, there are better metrics available.{" "}
          <br /> An add-to-cart button, for example, indicates more interest than just a product view. The same goes
          for zooming into a product image or selecting color variations.
        </p>
        <p>
          Note that in both scenarios, we are trying to find out how interested the visitor is in the content. But
          depending on the type of website, we choose different metrics.
        </p>
        <p>
          E-commerce websites offer more functionalities, so we have more indicators for the user's interest.
          Text-heavy sites instead, track broader metrics, since there are no better indicators to leverage.
        </p>
        <p>
          Using specific indicators over broader ones, is very important for re-targeting, for example. On Facebook,
          Instagram and Google ads, the target audience should be as precise as possible, since we pay per impression.{" "}
          <br /> So, depending on the type of website, there are different data points to collect.
        </p>
        <p>
          Ad spent, for example, is reviewed based on conversions. That's why conversion tracking
          {/* </Link> */} has a critical importance for {/* <Link to="/en/ecommerce-analytics"> */}
          e-commerce analytics.
          {/* </Link>. */}
        </p>
        <p>
          Healthcare or financial websites ask many questions to the visitor through forms. They go a lot more into
          detail than other websites. That's because they have complex products and therefore need to explain more.
          Also, the opportunity costs, related to a wrong product choice, are much higher.
        </p>
        <p>
          On such sites, optimizing the customer journey to lead visitors through the funnel, is the main focus.
          Additional error tracking for the form submissions would ensure a frictionless experience.
        </p>
        <p>
          As you can see, there are many different types of websites and depending on the business model, different
          KPIs have to be tracked. That's why a{" "}
          <Link to="/en/google-analytics-consultant">Google Analytics consultant</Link> considers all of these factors
          and helps you to make the right decisions for your analytics setup.
        </p>
        <H as="h2">Benefits of Google Analytics Consulting</H>
        <p>
          The benefit of Google Analytics consulting is the access to high quality data. It enables you to answer
          important business questions.
        </p>
        <p>
          Once you know the best-converting marketing channels and campaigns, you know where put your budget. Also
          knowing the best performing categories, topics or color variants is a huge help, when allocating resources.{" "}
        </p>
        <p>
          Help from a <Link to="/en/google-analytics-consultant">Google Analytics consultant</Link> sets focus on the
          right metrics and ensures quality. Not only that, it makes sure you finish the endeavor of becoming a
          "data-driven" business.
        </p>
        <p>
          Data and analytics by itself do not directly cause profit gains. Analytics consulting firms don't stress
          this enough. <br /> Web Analytics provides data to answer business questions and to discover sales problems.
          The <em>usage</em> of the data ultimately leads to business gains. <br />
          So, a Google Analytics consulting service benefits you by finding answers to your questions.
        </p>
        <p>
          Likewise, a car dashboard displays information about speed, oil pressure and engine temperature. When
          driving, it helps you to decide if you need to slow down or if there is anything wrong. This is a great
          benefit. However, the dashboard is not inherently necessary to drive the car. It is possible to drive it
          without any information and still arrive at the destination.
        </p>
        <p>
          The same goes for analytics consulting services. They are not necessary if you simply run a blog and want to
          know how many visitors you have per month.
          <br />
          But if you either make money with your website or you invest a lot in it, chances are you want to optimize
          sales and cost.
          <br /> And that is where analytics consulting services help you to do just that.
        </p>
        {/* <RelatedContent /> */}
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default AnalyticsConsulting;
