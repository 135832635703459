import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import ImgFixed from "./ImgFixed";

const StyledRelatedContentCardFlex = styled.div`
  flex: 1 1 0;
  flex-direction: column;
  margin: 0.5rem 0.25rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  min-width: 35%;

  :hover {
    box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.2);
  }
  .image-wrapper {
    display: flex;
    height: 150px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .card-image {
    display: flex;
    min-height: 100%;
    min-width: 100%;
    border-radius: 6px 6px 0px 0px;
  }

  .cardbody {
    padding: 1rem 0.5rem;
    background: var(--background);
    border-radius: 0px 0px 6px 6px;
    border-top: 1px solid var(--shadow);
  }

  h4 {
    font-size: 1rem;
    color: var(--copy);
  }

  a {
    display: contents;
  }
`;

const RelatedContentCardFlex = props => (
  <StyledRelatedContentCardFlex className="related-content-card">
    <Link to="#">
      <div className="image-wrapper">
        <ImgFixed ImageName={props.imageName} className="card-image" />
      </div>
      {/* <Img src={props.imageName} className="card-image"  /> */}
      <div className="cardbody">
        <h4>{props.title}</h4>
        {/* <Link to={props.title}>view</Link> */}
      </div>
    </Link>
  </StyledRelatedContentCardFlex>
);

export default React.memo(RelatedContentCardFlex);
